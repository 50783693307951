import * as React from 'react';
import { SVGProps } from 'react';

function SvgAccountboxOutlined(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      data-name="account box (outlined)"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}>
      <path data-name="Path 386" d="M0 0h24v24H0Z" fill="none" />
      <path
        data-name="Path 387"
        d="M19 5v14H5V5h14m0-2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2.006 2.006 0 0 0 2-2V5a2.006 2.006 0 0 0-2-2Zm-7 9a3 3 0 1 1 3-3 3.009 3.009 0 0 1-3 3Zm0-4a1 1 0 1 0 1 1 1 1 0 0 0-1-1Zm6 10H6v-1.53c0-2.5 3.97-3.58 6-3.58s6 1.08 6 3.58Zm-9.69-2h7.38A7.011 7.011 0 0 0 12 14.88 6.982 6.982 0 0 0 8.31 16Z"
      />
    </svg>
  );
}

export default SvgAccountboxOutlined;
