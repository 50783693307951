import { isRouteActive } from '@b7hio/core-lib/src/utils';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  Tooltip,
} from '@mui/material';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { useAuthLogin } from '../use-auth-login';

export interface NavItemProps {
  name: string;
  route: string;
  items?: readonly NavItemProps[];
  icon?: React.ReactNode;
  open?: boolean;
  roles?: string[];
}

export const NavItem = ({
  name,
  route,
  items,
  icon,
  roles,
}: NavItemProps): JSX.Element => {
  const router = useRouter();

  const isActive = isRouteActive(
    route,
    router.pathname,
    route === '/' || (items?.length ?? 0) > 0
  );
  const isParentOpen = isRouteActive(route, router.pathname, route === '/');

  const [open, setOpen] = useState(isParentOpen);

  const [user, handleLogout, refreshUser, hasRole] = useAuthLogin();

  const handleToggle = () => {
    setOpen((value) => !value);
  };

  const button = (
    <ListItemButton
      sx={{ m: 1, borderRadius: 1, whiteSpace: 'pre-wrap' }}
      component="a"
      selected={isActive}
      onClick={items && handleToggle}>
      {icon && <NavIcon>{icon}</NavIcon>}
      <ListItemText
        primary={name}
        primaryTypographyProps={{
          variant: 'body1',
          color: 'primary',
          sx: { fontWeight: 600 },
        }}
      />
      {items && (open ? <ExpandLess /> : <ExpandMore />)}
    </ListItemButton>
  );

  const shouldDisplay = roles ? hasRole(roles) : true;

  if (!shouldDisplay) {
    return <></>;
  }

  if (!items) {
    return (
      <Link href={route} passHref>
        {button}
      </Link>
    );
  }

  return (
    <>
      {button}
      {items.map((navigationItem) => (
        <Collapse
          key={navigationItem.route}
          in={open}
          timeout="auto"
          unmountOnExit>
          <List dense component="nav" disablePadding>
            <NavItem {...navigationItem} />
          </List>
        </Collapse>
      ))}
    </>
  );
};

const NavIcon = styled(ListItemIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
}));
