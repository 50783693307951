import * as React from 'react';
import { SVGProps } from 'react';

function SvgExitfullscreen(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      data-name="exit fullscreen"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}>
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        data-name="\u21B3Color"
        d="M16 19h-2v-5h5v2h-3v3Zm-6 0H8v-3H5v-2h5v5Zm9-9h-5V5h2v3h3v2Zm-9 0H5V8h3V5h2v5Z"
      />
    </svg>
  );
}

export default SvgExitfullscreen;
