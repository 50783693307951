import * as React from 'react';
import { SVGProps } from 'react';

function SvgInfoOutlined(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      data-name="info (outlined)"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}>
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        data-name="\u21B3Color"
        d="M11.99 22A10 10 0 1 1 22 12a10.006 10.006 0 0 1-10.01 10ZM12 4a8 8 0 1 0 8 8 8.009 8.009 0 0 0-8-8Zm1 13h-2v-6h2v6Zm0-8h-2V7h2v2Z"
      />
    </svg>
  );
}

export default SvgInfoOutlined;
