import * as React from 'react';
import { SVGProps } from 'react';

function SvgNotificationbellOutlined(
  props: SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      data-name="notification bell (outlined)"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}>
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        data-name="\u21B3Color"
        d="M12 21.5a2 2 0 0 1-2-2h4a2 2 0 0 1-2 2Zm8-3H4v-1l2-2v-5a7.086 7.086 0 0 1 1.177-4.06A5.529 5.529 0 0 1 10.5 4.18V3.5a1.5 1.5 0 0 1 3 0v.68a5.522 5.522 0 0 1 3.326 2.264A7.093 7.093 0 0 1 18 10.5v5l2 2v1ZM12 6a3.717 3.717 0 0 0-2.934 1.32A4.907 4.907 0 0 0 8 10.5v6h8v-6a4.908 4.908 0 0 0-1.066-3.18A3.716 3.716 0 0 0 12 6Z"
      />
    </svg>
  );
}

export default SvgNotificationbellOutlined;
