import * as React from 'react';
import { SVGProps } from 'react';

function SvgWallet(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      data-name="account_balance_wallet_black_24dp (1)"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}>
      <path data-name="Path 4828" d="M0 0h24v24H0Z" fill="none" />
      <path
        data-name="Path 4829"
        d="M21 18v1a2.006 2.006 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14a2.006 2.006 0 0 1 2 2v1h-9a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2Zm-9-2h10V8H12Zm4-2.5a1.5 1.5 0 1 1 1.5-1.5 1.5 1.5 0 0 1-1.5 1.5Z"
        fill="#4686c6"
      />
    </svg>
  );
}

export default SvgWallet;
