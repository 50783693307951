import * as React from 'react';
import { SVGProps } from 'react';

function SvgLink(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}>
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        data-name="\u21B3Color"
        d="M17 17h-4v-1.9h4a3.1 3.1 0 0 0 0-6.2h-4V7h4a5 5 0 0 1 0 10Zm-6 0H7A5 5 0 1 1 7 7h4v1.9H7a3.1 3.1 0 1 0 0 6.2h4V17Zm5-4H8v-2h8v2Z"
      />
    </svg>
  );
}

export default SvgLink;
