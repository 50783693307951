import * as React from 'react';
import { SVGProps } from 'react';

function SvgHamburgermenu(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      data-name="hamburger menu"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}>
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        data-name="\u21B3Color"
        d="M21 18H3v-2h18v2Zm0-5H3v-2h18v2Zm0-5H3V6h18v2Z"
      />
    </svg>
  );
}

export default SvgHamburgermenu;
