import { colorsHsla } from '../theme/colors';
import * as Generated from './generated';

export const icons = Generated;

export type KnownIcon = keyof typeof icons;

export type IconProps = {
  readonly name: KnownIcon;
  readonly size?: number | `${number}rem` | `${number}em` | `${number}%`;
} & (
  | {
      readonly gradient: boolean;
    }
  | {
      readonly color?: string;
    }
);

/**
 * @note gradient version might glitch under certain circumstances. Test some more
 */
export const Icon = ({
  name,
  size = '1.5rem',
  ...props
}: IconProps): JSX.Element => {
  // TODO check if it makes sense to extract the icon width/height
  const black = colorsHsla('black');
  const gradient = 'gradient' in props && props.gradient;
  const fill = gradient
    ? 'url(#lightGradient)'
    : 'color' in props
    ? props.color ?? black
    : black;
  const attributes = { height: size, width: size, ...props };
  const C = icons[name];
  return gradient ? (
    <svg
      role="img"
      style={{
        fill,
      }}>
      <g>
        <C {...attributes}>
          <defs>
            <linearGradient
              id="lightGradient"
              x1="0%"
              y1="0%"
              x2="100%"
              y2="100%">
              <stop stopColor={colorsHsla('brandGradientStart')} offset="0%" />
              <stop stopColor={colorsHsla('brandGradientEnd')} offset="100%" />
            </linearGradient>
          </defs>
        </C>
      </g>
    </svg>
  ) : (
    <C style={{ fill }} {...attributes} />
  );
};
