import * as React from 'react';
import { SVGProps } from 'react';

function SvgFilterlist(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      data-name="filter list"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}>
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        data-name="\u21B3Color"
        d="M14 18h-4v-2h4v2Zm4-5H6v-2h12v2Zm3-5H3V6h18v2Z"
      />
    </svg>
  );
}

export default SvgFilterlist;
