import * as React from 'react';
import { SVGProps } from 'react';

function SvgChevrondropdown(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      data-name="chevron drop down"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}>
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        data-name="\u21B3Color"
        d="M16.59 8.6 12 13.174 7.41 8.6 6 10.008 12 16l6-5.992Z"
      />
    </svg>
  );
}

export default SvgChevrondropdown;
