import * as React from 'react';
import { SVGProps } from 'react';

function SvgEyecrossed(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      data-name="Component 2337"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}>
      <path data-name="Rectangle 6589" fill="none" d="M0 0h24v24H0z" />
      <path
        d="m20.239 21.94-4.07-4a10.127 10.127 0 0 1-1.948.543 12.384 12.384 0 0 1-2.22.2 11.42 11.42 0 0 1-6.6-2.035 12.083 12.083 0 0 1-4.4-5.512A11.18 11.18 0 0 1 2.382 8.62a15.673 15.673 0 0 1 2.119-2.366L1.42 3.196l1.081-1.135L21.25 20.805Zm-8.238-6.61a4.44 4.44 0 0 0 .7-.062 2.2 2.2 0 0 0 .629-.185l-5.3-5.278a3.679 3.679 0 0 0-.16.629 4.132 4.132 0 0 0-.062.7 4.013 4.013 0 0 0 1.233 2.988 4.078 4.078 0 0 0 2.96 1.208Zm7 1.036-3.3-3.3a3.391 3.391 0 0 0 .358-.888 4.178 4.178 0 0 0-1.086-4.017 4.045 4.045 0 0 0-2.972-1.217 4.153 4.153 0 0 0-1.011.123 3.43 3.43 0 0 0-.913.37l-2.811-2.86a11.153 11.153 0 0 1 2.22-.691 12.365 12.365 0 0 1 2.639-.3 11.247 11.247 0 0 1 6.511 2.035 11.958 11.958 0 0 1 4.365 5.516 12.429 12.429 0 0 1-1.665 2.91 12.2 12.2 0 0 1-2.335 2.314Zm-4.587-4.587-3.083-3.083a2.113 2.113 0 0 1 1.295-.136 2.477 2.477 0 0 1 1.2.6 2.936 2.936 0 0 1 .691 1.135 2.352 2.352 0 0 1-.1 1.48Z"
        fill="#687076"
      />
    </svg>
  );
}

export default SvgEyecrossed;
