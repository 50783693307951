import * as React from 'react';
import { SVGProps } from 'react';

function SvgEdit(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}>
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        data-name="\u21B3Color"
        d="M6.75 21H3v-3.75L14.06 6.192l3.75 3.75ZM18.88 8.872l-3.75-3.75 1.83-1.83a1 1 0 0 1 1.41 0l2.34 2.34a1 1 0 0 1 0 1.41l-1.83 1.83Z"
      />
    </svg>
  );
}

export default SvgEdit;
