import * as React from 'react';
import { SVGProps } from 'react';

function SvgRecycle(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 40.6 40.6"
      {...props}>
      <path d="M28.65 25.9a9.084 9.084 0 0 0 1.3-2.725 10.591 10.591 0 0 0 .4-2.875 10.4 10.4 0 0 0-.725-3.775 10.021 10.021 0 0 0-2.175-3.375 10.136 10.136 0 0 0-3.225-2.125 9.631 9.631 0 0 0-3.675-.725l1.85-1.9-2.2-2.2-5.8 5.75 5.8 5.75 2.2-2.2-2.1-2.1a7.087 7.087 0 0 1 2.625.525A6.625 6.625 0 0 1 25.2 15.4a6.916 6.916 0 0 1 1.525 2.275 6.712 6.712 0 0 1 .525 2.575 6.619 6.619 0 0 1-.225 1.7 7.022 7.022 0 0 1-.675 1.65Zm-8.25 8.5 5.8-5.75-5.8-5.75-2.2 2.2 2.05 2.05a6.324 6.324 0 0 1-2.575-.45 6.6 6.6 0 0 1-2.275-1.5 6.916 6.916 0 0 1-1.525-2.275 6.712 6.712 0 0 1-.525-2.575 6.619 6.619 0 0 1 .225-1.7A7.022 7.022 0 0 1 14.25 17l-2.3-2.3a9.084 9.084 0 0 0-1.3 2.725 10.591 10.591 0 0 0-.4 2.875 10.092 10.092 0 0 0 .75 3.775 10.154 10.154 0 0 0 5.4 5.5 10.155 10.155 0 0 0 3.65.775L18.2 32.2Zm-.1 6.2a20.679 20.679 0 0 1-8.025-1.55A19.467 19.467 0 0 1 1.55 28.325 20.679 20.679 0 0 1 0 20.3a20.549 20.549 0 0 1 1.55-7.975 19.733 19.733 0 0 1 4.275-6.45 20.064 20.064 0 0 1 6.45-4.3A20.393 20.393 0 0 1 20.3 0a20.265 20.265 0 0 1 7.975 1.575 19.764 19.764 0 0 1 10.75 10.75A20.265 20.265 0 0 1 40.6 20.3a20.393 20.393 0 0 1-1.575 8.025 20.063 20.063 0 0 1-4.3 6.45 19.733 19.733 0 0 1-6.45 4.275A20.549 20.549 0 0 1 20.3 40.6Zm0-20.3Zm0 16.9a16.57 16.57 0 0 0 16.9-16.9A16.57 16.57 0 0 0 20.3 3.4 16.57 16.57 0 0 0 3.4 20.3a16.57 16.57 0 0 0 16.9 16.9Z" />
    </svg>
  );
}

export default SvgRecycle;
