import * as React from 'react';
import { SVGProps } from 'react';

function SvgEyeicon(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}>
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        data-name="\u21B3Color"
        d="M12 19.5a11.731 11.731 0 0 1-6.726-2.1A11.837 11.837 0 0 1 1 12a11.818 11.818 0 0 1 22 0 11.836 11.836 0 0 1-4.273 5.4A11.731 11.731 0 0 1 12 19.5ZM12 7a5 5 0 1 0 5 5 5.005 5.005 0 0 0-5-5Zm0 8a3 3 0 1 1 3-3 3 3 0 0 1-3 3Z"
        fill="#4686C6"
      />
    </svg>
  );
}

export default SvgEyeicon;
