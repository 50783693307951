import * as React from 'react';
import { SVGProps } from 'react';

function SvgFileclock(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      {...props}>
      <g data-name="Group 7543">
        <g data-name="Group 7407">
          <g data-name="Group 7406">
            <circle
              data-name="Ellipse 218"
              cx={24}
              cy={24}
              r={24}
              fill="#f1f3f5"
            />
            <path data-name="Rectangle 5349" fill="none" d="M10 10h28v28H10z" />
            <path
              data-name="Path 7143"
              d="M29.924 23.937a5.86 5.86 0 1 0 5.86 5.86 5.862 5.862 0 0 0-5.86-5.86Zm1.934 8.614-2.52-2.52v-3.75h1.172v3.27l2.168 2.168Zm-.762-19.162h-3.727a3.5 3.5 0 0 0-6.61 0h-3.727a2.351 2.351 0 0 0-2.344 2.344v17.58a2.351 2.351 0 0 0 2.344 2.344h7.156a7.9 7.9 0 0 1-1.664-2.344h-5.5v-17.58h2.352v3.512h9.376v-3.512h2.344v5.954a8.24 8.24 0 0 1 2.344.7v-6.654a2.351 2.351 0 0 0-2.344-2.344Zm-7.032 2.344a1.172 1.172 0 1 1 1.172-1.172 1.175 1.175 0 0 1-1.172 1.172Z"
              fill="#687076"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgFileclock;
