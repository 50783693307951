import * as React from 'react';
import { SVGProps } from 'react';

function SvgCalendarPageWithBg(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      data-name="Component 2476 \u2013 36"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 28 28"
      {...props}>
      <g data-name="Group 8858">
        <rect
          data-name="Rectangle 6661"
          width={28}
          height={28}
          rx={14}
          fill="#4686c6"
        />
        <g data-name="Component 2475">
          <path data-name="Rectangle 6695" fill="none" d="M2 2h24v24H2z" />
          <path
            d="M8.805 20.612a2.121 2.121 0 0 1-1.547-.629 2.1 2.1 0 0 1-.638-1.556V16h2.342V6l1.1 1.1 1.1-1.1 1.084 1.1 1.1-1.1 1.084 1.1 1.1-1.1 1.084 1.1 1.1-1.1 1.1 1.1L19.933 6v12.427a2.1 2.1 0 0 1-.638 1.556 2.121 2.121 0 0 1-1.547.629Zm8.949-1.4a.749.749 0 0 0 .787-.787V8.132h-8.2V16h6.629v2.429a.749.749 0 0 0 .787.787Zm-6.484-8.266V9.9h4.02v1.046Zm0 2.342V12.24h4.02v1.049Zm5.645-2.342a.538.538 0 1 1 .367-.157.5.5 0 0 1-.367.157Zm0 2.255a.538.538 0 1 1 .367-.157.5.5 0 0 1-.367.156Zm-8.128 6.012h6.8V17.4H8.018v1.031a.762.762 0 0 0 .769.787Zm-.769 0Z"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgCalendarPageWithBg;
