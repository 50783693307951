import ErrorBoundary from '@b7hio/core-lib/src/components/ErrorBoundary';
import { NextPage } from 'next';
import { appWithTranslation } from 'next-i18next';
import { DefaultSeo } from 'next-seo';
import type { AppProps as NextAppProps } from 'next/app';
import React from 'react';
import { useTranslation } from 'react-i18next';
import i18nextConfig from '../../next-i18next.config';
import { AppProviders } from '../app-providers';
import { useHMR } from '../hooks/use-hmr';
import { Shell } from '@/components/Shell';
import '@b7hio/core-lib/src/material-ui-x-license';
import '../config/amplify';
// Workaround for https://github.com/zeit/next.js/issues/8592

// eslint-disable-next-line @typescript-eslint/ban-types
export type Page<P = {}> = NextPage<P> & {
  readonly getLayout?: (page: React.ReactElement) => React.ReactNode;
  readonly layout?: React.ComponentType;
};

export type AppProps = NextAppProps & {
  /** Will be defined only is there was an error */
  readonly err?: Error;
  readonly Component: Page;
};

/**
 * @link https://nextjs.org/docs/advanced-features/custom-app
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MyApp = ({ Component, pageProps, err }: AppProps) => {
  useHMR();
  const getLayout = Component.getLayout ?? ((page) => page);
  const Layout = Component.layout ?? Shell;
  const { t } = useTranslation(['common']);

  return (
    <AppProviders {...pageProps}>
      <ErrorBoundary>
        <DefaultSeo titleTemplate={`%s | ${t('common:appName')}`} />
        <Layout>{getLayout(<Component {...pageProps} />)}</Layout>
      </ErrorBoundary>
    </AppProviders>
  );
};

// MyApp.getInitialProps = async (appContext: AppContext) => {
//   // calls page's `getInitialProps` and fills `appProps.pageProps`
//   const appProps = await App.getInitialProps(appContext);
//   // const amplify = withSSRContext({ req: appContext.ctx.req });

//   return { ...appProps };
// };

export default appWithTranslation(MyApp, i18nextConfig);
