import { SnackbarPackProvider } from '@b7hio/core-lib/src';
import { css, Global } from '@emotion/react';
import { LocalizationProvider } from '@mui/lab';
import AdapterLuxon from '@mui/lab/AdapterLuxon';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { FC } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import theme from './theme/theme';

const queryClient = new QueryClient();

/**
 * Global AppProviders (emotion, nextauth, etc...)
 */
export const AppProviders: FC = (props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterLuxon} locale="en-GB">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Global
          styles={css`
            .MuiDataGrid-iconButtonContainer {
              display: none;
            }

            .MuiDataGrid-columnHeader--sorted .MuiDataGrid-iconButtonContainer {
              display: inherit;
            }
          `}
        />
        <QueryClientProvider client={queryClient}>
          <SnackbarPackProvider>{props.children}</SnackbarPackProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </LocalizationProvider>
  );
};
