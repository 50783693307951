import * as React from 'react';
import { SVGProps } from 'react';

function SvgArrowleft(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      data-name="arrow left"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}>
      <path fill="none" d="M0 0h24v24H0z" />
      <path data-name="\u21B3Color" d="m14 7-5 5 5 5Z" />
    </svg>
  );
}

export default SvgArrowleft;
