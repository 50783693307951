import * as React from 'react';
import { SVGProps } from 'react';

function SvgBuilding(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      data-name="account_balance_black_24dp (3)"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}>
      <g data-name="Group 5658">
        <path data-name="Rectangle 4468" fill="none" d="M0 0h24v24H0z" />
      </g>
      <g data-name="Group 5660">
        <g data-name="Group 5659">
          <path
            data-name="Union 32"
            d="M2 22v-3h20v3Zm15-5v-7h3v7Zm-6.5 0v-7h3v7ZM4 17v-7h3v7ZM2 8V6l10-5 10 5v2Z"
            fill="#4686C6"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgBuilding;
