import * as React from 'react';
import { SVGProps } from 'react';

function SvgAccountboxSharp(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      data-name="account box (sharp)"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}>
      <path data-name="Path 390" d="M0 0h24v24H0Z" fill="none" />
      <path
        data-name="Path 391"
        d="M3 21h18V3H3ZM15 9a3 3 0 1 1-3-3 3 3 0 0 1 3 3Zm-9 8c0-2 4-3.1 6-3.1s6 1.1 6 3.1v1H6Z"
      />
    </svg>
  );
}

export default SvgAccountboxSharp;
