import * as React from 'react';
import { SVGProps } from 'react';

function SvgDocumentsmenu(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 19.66 26.898"
      {...props}>
      <g data-name="Component 1817 \u2013 2">
        <g data-name="Component 1818 \u2013 3" fill="#dedfe9">
          <path data-name="Path 3563" d="M12.655.267v6.79h6.79Z" />
          <path
            data-name="Path 3564"
            d="M11.505 9.355a1.15 1.15 0 0 1-1.15-1.15V0H2.924A2.928 2.928 0 0 0 0 2.924v21.049a2.922 2.922 0 0 0 2.913 2.925h13.822a2.928 2.928 0 0 0 2.924-2.925V9.355Zm-9.276-6.49h5.8v2.3h-5.8Zm0 4.179h5.8v2.3h-5.8Zm15.218 15.1H2.229v-2.3h15.218Zm0-4.18H2.229v-2.3h15.218Z"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgDocumentsmenu;
