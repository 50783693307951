import * as React from 'react';
import { SVGProps } from 'react';

function SvgCheckboxUnchecked(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      data-name="check box (unchecked)"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}>
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        data-name="\u21B3Color"
        d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2ZM5 5v14h14V5Z"
      />
    </svg>
  );
}

export default SvgCheckboxUnchecked;
