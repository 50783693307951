import * as React from 'react';
import { SVGProps } from 'react';

function SvgRadiobuttonChecked(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      data-name="radio button (checked)"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}>
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        data-name="\u21B3Color"
        d="M12 22a10 10 0 1 1 10-10 10.011 10.011 0 0 1-10 10Zm0-18a8 8 0 1 0 8 8 8.009 8.009 0 0 0-8-8Zm0 13a5 5 0 1 1 5-5 5.006 5.006 0 0 1-5 5Z"
      />
    </svg>
  );
}

export default SvgRadiobuttonChecked;
