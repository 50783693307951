import * as React from 'react';
import { SVGProps } from 'react';

function SvgNotificationImportant(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      data-name="notification (important)"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}>
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        data-name="\u21B3Color"
        d="M12 23a1.992 1.992 0 0 1-1.99-1.99h3.98A1.992 1.992 0 0 1 12 23Zm9-3H3v-1l2-2v-6a6.992 6.992 0 0 1 5.5-6.83V3a1.5 1.5 0 0 1 3 0v1.17A6.992 6.992 0 0 1 19 11v6l2 2v1Zm-10-6v2h2v-2h-2Zm0-6v4h2V8h-2Z"
      />
    </svg>
  );
}

export default SvgNotificationImportant;
