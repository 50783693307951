import * as React from 'react';
import { SVGProps } from 'react';

function SvgCirclecheckedFilled(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      data-name="circle checked (filled)"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}>
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        data-name="\u21B3Color"
        d="M12 22a10 10 0 1 1 10-10 10.011 10.011 0 0 1-10 10ZM6.41 10.59 5 12l5 5 9-9-1.41-1.42L10 14.17l-3.59-3.58Z"
      />
    </svg>
  );
}

export default SvgCirclecheckedFilled;
