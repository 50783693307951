import * as React from 'react';
import { SVGProps } from 'react';

function SvgUserOutlined(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      data-name="user (outlined)"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}>
      <path fill="none" d="M0 0h24v24H0z" />
      <path data-name="Path 384" d="M0 0h24v24H0Z" fill="none" />
      <path
        data-name="Path 385"
        d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2Zm0 3a3 3 0 1 1-3 3 3 3 0 0 1 3-3Zm0 14.2a7.2 7.2 0 0 1-6-3.22c.03-1.99 4-3.08 6-3.08s5.97 1.09 6 3.08a7.2 7.2 0 0 1-6 3.22Z"
      />
    </svg>
  );
}

export default SvgUserOutlined;
