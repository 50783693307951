import * as React from 'react';
import { SVGProps } from 'react';

function SvgCheckboxChecked(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      data-name="check box (checked)"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}>
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        data-name="\u21B3Color"
        d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2ZM6.41 10.59 5 12l5 5 9-9-1.41-1.42L10 14.17l-3.59-3.58Z"
      />
    </svg>
  );
}

export default SvgCheckboxChecked;
