import * as React from 'react';
import { SVGProps } from 'react';

function SvgCarticon(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      data-name="shopping_cart_black_24dp (4)"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}>
      <defs>
        <linearGradient
          id="cart_icon_svg__a"
          x1={0.5}
          y1={-0.404}
          x2={0.5}
          y2={1}
          gradientUnits="objectBoundingBox">
          <stop offset={0} stopColor="#8972fb" />
          <stop offset={1} stopColor="#4862f4" />
        </linearGradient>
      </defs>
      <path data-name="Path 5359" d="M0 0h24v24H0Z" fill="none" />
      <path
        data-name="Path 5360"
        d="M7 18a2 2 0 1 0 2 2 2 2 0 0 0-2-2ZM1 2v2h2l3.6 7.59-1.35 2.45A1.933 1.933 0 0 0 5 15a2.006 2.006 0 0 0 2 2h12v-2H7.42a.248.248 0 0 1-.25-.25l.03-.12.9-1.63h7.45a1.991 1.991 0 0 0 1.75-1.03l3.58-6.49A.977.977 0 0 0 21 5a1 1 0 0 0-1-1H5.21l-.94-2H1Zm16 16a2 2 0 1 0 2 2 2 2 0 0 0-2-2Z"
        fill="url(#cart_icon_svg__a)"
      />
    </svg>
  );
}

export default SvgCarticon;
