import * as React from 'react';
import { SVGProps } from 'react';

function SvgSettings(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}>
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        data-name="\u21B3Color"
        d="M13.851 21.5h-3.7a.474.474 0 0 1-.463-.4l-.347-2.518a6.712 6.712 0 0 1-1.562-.926l-2.3.95a.494.494 0 0 1-.16.027.455.455 0 0 1-.407-.24L3.055 15.1a.5.5 0 0 1 .116-.606l1.955-1.568A6.172 6.172 0 0 1 5.057 12a6.06 6.06 0 0 1 .081-.926L3.182 9.507a.468.468 0 0 1-.115-.607l1.851-3.29a.459.459 0 0 1 .4-.245.444.444 0 0 1 .165.032l2.3.95a6.934 6.934 0 0 1 1.567-.926L9.7 2.9a.454.454 0 0 1 .448-.4h3.7a.467.467 0 0 1 .463.4l.347 2.518a6.745 6.745 0 0 1 1.562.926l2.3-.95a.494.494 0 0 1 .16-.027.455.455 0 0 1 .407.24L20.945 8.9a.5.5 0 0 1-.116.606l-1.956 1.568a6.069 6.069 0 0 1 .069.926 7.949 7.949 0 0 1-.058.926l1.933 1.568a.468.468 0 0 1 .115.606l-1.851 3.289a.459.459 0 0 1-.4.245.444.444 0 0 1-.165-.032l-2.3-.95a6.869 6.869 0 0 1-1.562.926L14.3 21.1a.443.443 0 0 1-.449.4ZM12 8.438a3.564 3.564 0 0 0 0 7.125 3.564 3.564 0 0 0 0-7.125Z"
      />
    </svg>
  );
}

export default SvgSettings;
