import * as React from 'react';
import { SVGProps } from 'react';

function SvgAdd(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}>
      <path fill="none" d="M0 0h24v24H0z" />
      <path data-name="\u21B3Color" d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6Z" />
    </svg>
  );
}

export default SvgAdd;
