import * as React from 'react';
import { SVGProps } from 'react';

function SvgArrowup(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      data-name="arrow up"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}>
      <path d="M12.92 19.84h-2v-12l-5.5 5.5L4 11.92 11.92 4l7.92 7.92-1.42 1.42-5.5-5.5Z" />
      <path fill="none" d="M0 0h24v24H0z" />
    </svg>
  );
}

export default SvgArrowup;
