import { TimePicker as MuiTimePicker } from '@mui/lab';
import { TextField } from '@mui/material';
import { DateTime } from 'luxon';
import React, { useRef } from 'react';
import type { UseControllerProps } from 'react-hook-form';
import { useController } from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types';
import type { TFunction } from 'react-i18next';
import { getFieldErrors } from '../../../form';

export interface TimePickerProps<T> extends UseControllerProps<T> {
  readonly label: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly t: TFunction<any>;
  readonly getErrorPath?: (type: string) => string | undefined;
}

export const FormTimePicker = <T extends FieldValues>({
  t,
  label,
  getErrorPath,
  ...props
}: TimePickerProps<T>): JSX.Element => {
  const {
    field: { ref, ...field },
    formState,
  } = useController(props);
  //TODO: review null fallback
  const value = field.value
    ? DateTime.fromISO(field.value)
    : field.value ?? null;

  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <MuiTimePicker<DateTime>
      label={label}
      ampm={false}
      renderInput={(params) => (
        <TextField
          {...params}
          {...getFieldErrors(field.name, formState, t, getErrorPath)}
          id={field.name}
          name={field.name}
          data-testid={field.name}
          onBlur={field.onBlur}
          ref={ref}
        />
      )}
      {...field}
      value={value}
      disabled={formState.isSubmitting}
      // Tied to the focus/blur hack
      inputRef={inputRef}
      onClose={() => {
        setTimeout(() => {
          inputRef.current?.focus();
          inputRef.current?.blur();
        }, 0);
      }}
      onChange={(time) => {
        field.onChange(
          time?.toISOTime({
            suppressMilliseconds: true,
            suppressSeconds: true,
            includeOffset: false,
          })
        );
      }}
    />
  );
};
