import * as React from 'react';
import { SVGProps } from 'react';

function SvgNavigatenext(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      data-name="navigate next"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}>
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        data-name="\u21B3Color"
        d="M10.008 6 8.6 7.41 13.174 12 8.6 16.59 10.008 18 16 12Z"
      />
    </svg>
  );
}

export default SvgNavigatenext;
