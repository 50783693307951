import * as React from 'react';
import { SVGProps } from 'react';

function SvgAccountbox(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      data-name="account box"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}>
      <path data-name="Path 388" d="M0 0h24v24H0Z" fill="none" />
      <path
        fill="#FFF"
        data-name="Path 389"
        d="M3 5v14a2 2 0 0 0 2 2h14a2.006 2.006 0 0 0 2-2V5a2.006 2.006 0 0 0-2-2H5a2 2 0 0 0-2 2Zm12 4a3 3 0 1 1-3-3 3 3 0 0 1 3 3Zm-9 8c0-2 4-3.1 6-3.1s6 1.1 6 3.1v1H6Z"
      />
    </svg>
  );
}

export default SvgAccountbox;
