import * as React from 'react';
import { SVGProps } from 'react';

function SvgWaringOutlined(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      data-name="waring (outlined)"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}>
      <path fill="none" d="M0 0h24v24H0z" />
      <path data-name="\u21B3Color" d="M13 19h-2v-2h2v2Zm0-4h-2V9h2v6Z" />
      <path
        data-name="Path 379"
        d="M12.001 2.984 22.82 21.015H1.182Z"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgWaringOutlined;
