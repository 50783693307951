import * as React from 'react';
import { SVGProps } from 'react';

function SvgRecyclecircle(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="1em" width="1em" {...props}>
      <path d="M32.35 29.6q.9-1.3 1.3-2.725t.4-2.875q0-1.9-.725-3.775T31.15 16.85q-1.45-1.4-3.225-2.125Q26.15 14 24.25 14l1.85-1.9-2.2-2.2-5.8 5.75 5.8 5.75 2.2-2.2-2.1-2.1q1.3 0 2.625.525T28.9 19.1q1 1 1.525 2.275.525 1.275.525 2.575 0 .85-.225 1.7t-.675 1.65Zm-8.25 8.5 5.8-5.75-5.8-5.75-2.2 2.2 2.05 2.05q-1.3.05-2.575-.45-1.275-.5-2.275-1.5t-1.525-2.275q-.525-1.275-.525-2.575 0-.85.225-1.7t.675-1.65l-2.3-2.3q-.9 1.3-1.3 2.725T13.95 24q0 1.9.75 3.775t2.2 3.325q1.45 1.45 3.2 2.175 1.75.725 3.65.775L21.9 35.9Zm-.1 6.2q-4.3 0-8.025-1.55-3.725-1.55-6.45-4.275Q6.8 35.75 5.25 32.025 3.7 28.3 3.7 24q0-4.25 1.55-7.975Q6.8 12.3 9.525 9.575q2.725-2.725 6.45-4.3Q19.7 3.7 24 3.7q4.25 0 7.975 1.575 3.725 1.575 6.45 4.3 2.725 2.725 4.3 6.45Q44.3 19.75 44.3 24q0 4.3-1.575 8.025-1.575 3.725-4.3 6.45-2.725 2.725-6.45 4.275Q28.25 44.3 24 44.3ZM24 24Zm0 16.9q7.2 0 12.05-4.85Q40.9 31.2 40.9 24q0-7.2-4.85-12.05Q31.2 7.1 24 7.1q-7.2 0-12.05 4.85Q7.1 16.8 7.1 24q0 7.2 4.85 12.05Q16.8 40.9 24 40.9Z" />
    </svg>
  );
}

export default SvgRecyclecircle;
