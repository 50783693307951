import * as React from 'react';
import { SVGProps } from 'react';

function SvgUsericon(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 26.378 26.378"
      {...props}>
      <g
        data-name="Group 4992"
        transform="translate(-369.839 -91.101)"
        fill="#dedfe9">
        <circle
          data-name="Ellipse 71"
          cx={3.401}
          cy={3.401}
          r={3.401}
          transform="translate(379.627 96.568)"
        />
        <g data-name="Group 3702">
          <path
            data-name="Path 3561"
            d="M381.677 106.093a8.358 8.358 0 0 0-1.008.076l2.358 2.882 2.362-2.886a8.524 8.524 0 0 0-.965-.072Z"
          />
          <path
            data-name="Path 3562"
            d="M383.028 91.1a13.189 13.189 0 1 0 13.189 13.189A13.188 13.188 0 0 0 383.028 91.1Zm8.921 19.914a7.969 7.969 0 0 0-4.03-4.15l-3.963 4.841c-.023.029-.058.039-.084.065a1.194 1.194 0 0 1-.084.1 1.284 1.284 0 0 1-.177.1 1.137 1.137 0 0 1-.127.071 1.194 1.194 0 0 1-.456.1 1.193 1.193 0 0 1-.456-.1 1.052 1.052 0 0 1-.127-.071 1.231 1.231 0 0 1-.177-.1 1.028 1.028 0 0 1-.084-.1c-.026-.026-.061-.036-.084-.065l-3.949-4.824a7.967 7.967 0 0 0-4 4.188 11.19 11.19 0 1 1 17.8-.055Z"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgUsericon;
