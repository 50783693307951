import * as React from 'react';
import { SVGProps } from 'react';

function SvgAddcircleOutline(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      data-name="add circle (outline)"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}>
      <path data-name="Path 394" d="M0 0h24v24H0Z" fill="none" />
      <path
        data-name="Path 395"
        d="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4Zm-1-5a10 10 0 1 0 10 10A10 10 0 0 0 12 2Zm0 18a8 8 0 1 1 8-8 8.011 8.011 0 0 1-8 8Z"
      />
    </svg>
  );
}

export default SvgAddcircleOutline;
