import * as React from 'react';
import { SVGProps } from 'react';

function SvgCheck(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}>
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        data-name="\u21B3Color"
        d="m8.727 16.075-4.3-4.388L3 13.149 8.727 19 21 6.463 19.568 5Z"
      />
    </svg>
  );
}

export default SvgCheck;
