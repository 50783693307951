import * as React from 'react';
import { SVGProps } from 'react';

function SvgTrashcan(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      data-name="trash can"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}>
      <path fill="none" d="M0 0h24v24H0z" />
      <path data-name="Path 401" d="M0 0h24v24H0Z" fill="none" />
      <path
        data-name="Path 402"
        d="M6 19a2.006 2.006 0 0 0 2 2h8a2.006 2.006 0 0 0 2-2V7H6ZM19 4h-3.5l-1-1h-5l-1 1H5v2h14Z"
      />
    </svg>
  );
}

export default SvgTrashcan;
