import * as React from 'react';
import { SVGProps } from 'react';

function SvgCopy(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}>
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        data-name="\u21B3Color"
        d="M19 23H8a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h11a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2ZM8 7v14h11V7ZM4 17H2V3a2 2 0 0 1 2-2h12v2H4v14Z"
      />
    </svg>
  );
}

export default SvgCopy;
