import * as React from 'react';
import { SVGProps } from 'react';

function SvgAddcircle(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      data-name="add circle"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}>
      <path data-name="Path 392" d="M0 0h24v24H0Z" fill="none" />
      <path
        data-name="Path 393"
        d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2Zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4Z"
      />
    </svg>
  );
}

export default SvgAddcircle;
