import * as React from 'react';
import { SVGProps } from 'react';

function SvgFullscreen(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}>
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        data-name="\u21B3Color"
        d="M19 19h-5v-2h3v-3h2v5Zm-9 0H5v-5h2v3h3v2Zm9-9h-2V7h-3V5h5v5ZM7 10H5V5h5v2H7v3Z"
      />
    </svg>
  );
}

export default SvgFullscreen;
