import * as React from 'react';
import { SVGProps } from 'react';

function SvgDownload(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}>
      <path data-name="Path 2414" d="M0 0h24v24H0Z" fill="none" />
      <path
        data-name="Path 2415"
        d="M19 9h-4V3H9v6H5l7 7ZM5 18v2h14v-2Z"
        fill="#687076"
      />
    </svg>
  );
}

export default SvgDownload;
