import * as React from 'react';
import { SVGProps } from 'react';

function SvgInboxarrowup(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      data-name="Inbox Arrow Up"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}>
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M14 14h-4v-3H8l4-4 4 4h-2v3m-9 1V5h14v10h-4a3 3 0 0 1-6 0H5M19 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2" />
    </svg>
  );
}

export default SvgInboxarrowup;
