import * as React from 'react';
import { SVGProps } from 'react';

function SvgMoneyicon(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      {...props}>
      <defs>
        <linearGradient
          id="money_icon_svg__a"
          x1={0.5}
          x2={0.5}
          y2={1}
          gradientUnits="objectBoundingBox">
          <stop offset={0} stopColor="#8972fb" />
          <stop offset={1} stopColor="#4862f4" />
        </linearGradient>
      </defs>
      <g data-name="Group 2033">
        <path data-name="Rectangle 1218" fill="none" d="M0 0h20v20H0z" />
      </g>
      <g data-name="Group 2034">
        <path
          data-name="Path 2306"
          d="M9.167 2a7.167 7.167 0 1 0 7.167 7.167A7.169 7.169 0 0 0 9.167 2ZM9.8 13.295v.258a.632.632 0 0 1-.631.631.632.632 0 0 1-.631-.631v-.3a2.874 2.874 0 0 1-1.928-1.505.618.618 0 0 1 .323-.846l.05-.021a.61.61 0 0 1 .774.28 1.677 1.677 0 0 0 1.519.982c.667 0 1.419-.344 1.419-1.154 0-.688-.5-1.046-1.634-1.455-.788-.28-2.4-.738-2.4-2.372a2.206 2.206 0 0 1 1.875-2.123v-.258a.632.632 0 0 1 .631-.631.632.632 0 0 1 .631.631v.265a2.429 2.429 0 0 1 1.548.932.609.609 0 0 1-.258.932.626.626 0 0 1-.731-.2 1.362 1.362 0 0 0-1.147-.553c-.5 0-1.3.265-1.3 1 0 .681.616.939 1.892 1.362 1.72.595 2.157 1.469 2.157 2.473A2.376 2.376 0 0 1 9.8 13.295Z"
          transform="translate(.834 .833)"
          fill="url(#money_icon_svg__a)"
        />
      </g>
    </svg>
  );
}

export default SvgMoneyicon;
