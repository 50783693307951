import * as React from 'react';
import { SVGProps } from 'react';

function SvgCalendar(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}>
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        data-name="icon/datetime/calendar"
        d="M19 20H5V9h14m-3-7v2H8V2H6v2H5a1.993 1.993 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1V2m-1 11h-5v5h5Z"
      />
    </svg>
  );
}

export default SvgCalendar;
