import * as React from 'react';
import { SVGProps } from 'react';

function SvgCancel(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}>
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        data-name="\u21B3Color"
        d="M12 22A10 10 0 0 1 4.926 4.926a10.004 10.004 0 0 1 14.148 14.148A9.937 9.937 0 0 1 12 22Zm0-8.59L15.59 17 17 15.59 13.41 12 17 8.41 15.59 7 12 10.59 8.41 7 7 8.41 10.59 12 7 15.59 8.41 17 12 13.411Z"
      />
    </svg>
  );
}

export default SvgCancel;
