import * as React from 'react';
import { SVGProps } from 'react';

function SvgCreditcart(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      data-name="credit_card_black_24dp (3)"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}>
      <path data-name="Path 5538" d="M0 0h24v24H0Z" fill="none" />
      <path
        data-name="Path 5539"
        d="M20 4H4a1.985 1.985 0 0 0-1.99 2L2 18a1.993 1.993 0 0 0 2 2h16a1.993 1.993 0 0 0 2-2V6a1.993 1.993 0 0 0-2-2Zm0 14H4v-6h16Zm0-10H4V6h16Z"
        fill="#4686C6"
      />
    </svg>
  );
}

export default SvgCreditcart;
