import * as React from 'react';
import { SVGProps } from 'react';

function SvgTag(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}>
      <path fill="none" d="M0 0h24v24H0z" />
      <path data-name="Boundary" fill="none" d="M0 0h24v24H0z" />
      <path d="M5.5 7A1.5 1.5 0 1 1 7 5.5 1.5 1.5 0 0 1 5.5 7m15.91 4.58-9-9A1.987 1.987 0 0 0 11 2H4a1.993 1.993 0 0 0-2 2v7a1.955 1.955 0 0 0 .59 1.41l8.99 9A2.038 2.038 0 0 0 13 22a2 2 0 0 0 1.41-.59l7-7A1.955 1.955 0 0 0 22 13a2 2 0 0 0-.59-1.42Z" />
    </svg>
  );
}

export default SvgTag;
