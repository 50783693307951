import * as React from 'react';
import { SVGProps } from 'react';

function SvgCashtransactions(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      data-name="Group 8067"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 36.312 23.883"
      {...props}>
      <g data-name="Group 3695" fill="#dedfe9">
        <path
          data-name="Path 3550"
          d="M13.024 15.623h1.843a11.045 11.045 0 0 1-.262-2.449h-2.1a11.863 11.863 0 0 0 .519 2.449Z"
        />
        <path
          data-name="Path 3551"
          d="M17.114 21.41c.122-.077.247-.15.366-.231a8.821 8.821 0 0 1-1.049-2.328 7.471 7.471 0 0 1-.55-.764h-1.728a11.988 11.988 0 0 0 2.961 3.323Z"
        />
        <path
          data-name="Path 3552"
          d="M16.193 4.386a3.555 3.555 0 0 1 1.069-1.8c-.056-.036-.117-.067-.173-.1a11.946 11.946 0 0 0-2.943 3.309h1.678l.369-1.409Z"
        />
        <path
          data-name="Path 3553"
          d="M12.729 9.297a11.814 11.814 0 0 0-.226 1.412h2.275c.11-.814.264-1.634.444-2.448h-2.209q-.164.505-.284 1.036Z"
        />
        <path
          data-name="Path 3554"
          d="M6.147 16.855a1.233 1.233 0 0 1 1.233-1.232h3.064a14.218 14.218 0 0 1-.422-2.449H4.588a1.233 1.233 0 1 1 0-2.465h5.447a14.47 14.47 0 0 1 .291-1.958c.037-.166.088-.326.131-.49H7.38a1.233 1.233 0 0 1 0-2.465h3.965a14.342 14.342 0 0 1 3.275-4.477q-.477-.156-.973-.269a11.214 11.214 0 0 0-2.483-.28V.385.77a11.17 11.17 0 0 0-2.463 22.063 11.2 11.2 0 0 0 5.955-.27 14.444 14.444 0 0 1-2.471-2.946 14.723 14.723 0 0 1-.828-1.53H7.382a1.233 1.233 0 0 1-1.235-1.232Z"
        />
      </g>
      <path
        data-name="Path 3555"
        d="M27.016.299a11.939 11.939 0 1 0 9 14.287 11.939 11.939 0 0 0-9-14.287Zm-1.572 17.06v1.296a.257.257 0 0 1-.256.257h-1.891a.257.257 0 0 1-.256-.257v-1.34a5.523 5.523 0 0 1-2.744-1.51.257.257 0 0 1 .01-.363l1.373-1.3a.3.3 0 0 1 .183-.07.259.259 0 0 1 .18.08 3.271 3.271 0 0 0 2.255.9c.9 0 1.814-.274 1.814-.884.006-.766-.351-1.029-2.122-1.564a8.109 8.109 0 0 1-2.284-.956 3.074 3.074 0 0 1-1.4-2.677 3.271 3.271 0 0 1 2.739-3.167V4.426a.257.257 0 0 1 .256-.257h1.891a.257.257 0 0 1 .256.257V5.68a6.063 6.063 0 0 1 2.4.841.259.259 0 0 1 .076.355l-1.025 1.587a.256.256 0 0 1-.354.077 3.875 3.875 0 0 0-1.934-.518c-.875 0-1.9.25-1.9.954 0 .575.246.806 1.98 1.332a8.065 8.065 0 0 1 2.4 1.015 3.281 3.281 0 0 1 1.433 2.854c-.011 1.624-1.185 2.836-3.08 3.178Z"
        fill="#dedfe9"
      />
    </svg>
  );
}

export default SvgCashtransactions;
