import * as React from 'react';
import { SVGProps } from 'react';

function SvgTrashX(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      data-name="delete_forever_black_24dp (1)"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}>
      <path data-name="Path 5371" d="M0 0h24v24H0Z" fill="none" />
      <path data-name="Path 5372" d="M0 0h24v24H0Z" fill="none" />
      <path
        data-name="Path 5373"
        d="M6 19a2.006 2.006 0 0 0 2 2h8a2.006 2.006 0 0 0 2-2V7H6Zm2.46-7.12 1.41-1.41L12 12.59l2.12-2.12 1.41 1.41L13.41 14l2.12 2.12-1.41 1.41L12 15.41l-2.12 2.12-1.41-1.41L10.59 14ZM15.5 4l-1-1h-5l-1 1H5v2h14V4Z"
        fill="#be4444"
      />
    </svg>
  );
}

export default SvgTrashX;
