import * as React from 'react';
import { SVGProps } from 'react';

function SvgMoreVertical(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      data-name="more (vertical)"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}>
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        data-name="\u21B3Color"
        d="M12 20a2 2 0 1 1 2-2 2 2 0 0 1-2 2Zm0-6a2 2 0 1 1 2-2 2 2 0 0 1-2 2Zm0-6a2 2 0 1 1 2-2 2 2 0 0 1-2 2Z"
      />
    </svg>
  );
}

export default SvgMoreVertical;
