import * as React from 'react';
import { SVGProps } from 'react';

function SvgDocsWithBg(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      data-name="Component 2476 \u2013 37"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 28 28"
      {...props}>
      <g data-name="Group 8858">
        <rect
          data-name="Rectangle 6661"
          width={28}
          height={28}
          rx={14}
          fill="#4686c6"
        />
        <g data-name="Component 2475">
          <path data-name="Rectangle 6695" fill="none" d="M2 2h24v24H2z" />
          <path
            data-name="description_FILL0_wght600_GRAD0_opsz48 (1)"
            d="M11.077 18.189h5.826v-1.194h-5.826Zm0-3.076h5.826v-1.194h-5.826Zm-1.7 6.387a1.452 1.452 0 0 1-1.43-1.429V7.95a1.394 1.394 0 0 1 .425-1.022 1.373 1.373 0 0 1 1-.425h6.55l4.125 4.122v9.445a1.373 1.373 0 0 1-.425 1 1.394 1.394 0 0 1-1.022.425Zm5.808-10.187V7.95H9.376v12.121h9.228v-8.758ZM9.376 7.95Z"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgDocsWithBg;
