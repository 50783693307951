import * as React from 'react';
import { SVGProps } from 'react';

function SvgAccountsicon(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24.293 28.377"
      {...props}>
      <g data-name="Component 1819 \u2013 3" fill="#dedfe9">
        <path
          data-name="Path 3546"
          d="M23.934 23.488H.359a.345.345 0 0 0-.359.327v4.235a.345.345 0 0 0 .359.327h23.575a.345.345 0 0 0 .359-.327v-4.235a.345.345 0 0 0-.359-.327Z"
        />
        <g data-name="Group 3693">
          <path
            data-name="Rectangle 2165"
            d="M3.019 13.029h2.178v8.65H3.019z"
          />
          <path
            data-name="Rectangle 2166"
            d="M8.382 13.029h2.178v8.65H8.382z"
          />
          <path
            data-name="Rectangle 2167"
            d="M13.745 13.029h2.178v8.65h-2.178z"
          />
          <path
            data-name="Rectangle 2168"
            d="M19.109 13.029h2.178v8.65h-2.178z"
          />
        </g>
        <path
          data-name="Path 3547"
          d="M12.153 0 .012 8.289H.003v2.274a.66.66 0 0 0 .657.658h22.979a.66.66 0 0 0 .657-.658V8.289Zm-.007 8.232a2.193 2.193 0 1 1 2.193-2.193 2.193 2.193 0 0 1-2.193 2.193Z"
        />
      </g>
    </svg>
  );
}

export default SvgAccountsicon;
