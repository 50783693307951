import * as React from 'react';
import { SVGProps } from 'react';

function SvgHomeicon(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 27.683 27.065"
      {...props}>
      <g data-name="Group 3706">
        <g data-name="Group 3697" fill="#dedfe9">
          <path
            data-name="Path 3556"
            d="M27.579 13.645a.724.724 0 0 0-.15-.929l-6.6-6.466h-7.892a1.2 1.2 0 0 1 0-2.4h5.443L14.844.385a1.516 1.516 0 0 0-2.017 0L4.371 8.674h9.539a1.2 1.2 0 1 1 0 2.4H1.925L.249 12.717a.735.735 0 0 0-.146.929Zm-9.15-4.972h1.752a1.2 1.2 0 1 1 0 2.4h-1.75a1.2 1.2 0 1 1 0-2.4Z"
          />
          <path
            data-name="Path 3557"
            d="M3.304 15.945v9.614a1.509 1.509 0 0 0 1.505 1.5h4.515a1.51 1.51 0 0 0 1.505-1.5v-6.524h6.02v6.524a1.509 1.509 0 0 0 1.505 1.5h4.515a1.51 1.51 0 0 0 1.505-1.5v-9.615Z"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgHomeicon;
