import * as React from 'react';
import { SVGProps } from 'react';

function SvgUsericonnavbar(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 18 18"
      {...props}>
      <path
        data-name="Path 5311"
        d="M9 0a9 9 0 1 0 9 9 9 9 0 0 0-9-9Zm0 2.7a2.7 2.7 0 1 1-2.7 2.7A2.7 2.7 0 0 1 9 2.7Zm0 12.78a6.48 6.48 0 0 1-5.4-2.9c.027-1.791 3.6-2.772 5.4-2.772s5.373.981 5.4 2.772a6.48 6.48 0 0 1-5.4 2.9Z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgUsericonnavbar;
