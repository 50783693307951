import * as React from 'react';
import { SVGProps } from 'react';

function SvgNotificationbellFilled(
  props: SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      data-name="notification bell (filled)"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}>
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        data-name="\u21B3Color"
        d="M12 21.5a2 2 0 0 1-2-2h4a2 2 0 0 1-2 2Zm8-3H4v-1l2-2v-5a7.1 7.1 0 0 1 1.174-4.06A5.522 5.522 0 0 1 10.5 4.18V3.5a1.5 1.5 0 0 1 3 0v.68a5.529 5.529 0 0 1 3.322 2.264A7.085 7.085 0 0 1 18 10.5v5l2 2v1Z"
      />
    </svg>
  );
}

export default SvgNotificationbellFilled;
