import * as React from 'react';
import { SVGProps } from 'react';

function SvgChevrondropup(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      data-name="chevron drop up"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}>
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        data-name="\u21B3Color"
        d="m12 8-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14Z"
      />
    </svg>
  );
}

export default SvgChevrondropup;
