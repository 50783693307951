import { bondsmithTheme } from '@b7hio/core-lib/src';
import { createTheme } from '@mui/material';

const theme = createTheme(bondsmithTheme, {
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
        },
      },
    },
    MuiLoadingButton: {
      styleOverrides: {
        root: {
          '&.MuiLoadingButton-loading': {
            color: '#757575',
            '&.Mui-disabled': {
              border: '1px solid #757575',
            },
          },
        },
        loadingIndicator: {
          color: 'white',
        },
      },
    },
  },
});

export default theme;
