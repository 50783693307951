import * as React from 'react';
import { SVGProps } from 'react';

function SvgClock(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}>
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        data-name="icon/datetime/clock"
        d="M12 20a8 8 0 1 0-8-8 8 8 0 0 0 8 8m0-18A10 10 0 1 1 2 12 10 10 0 0 1 12 2m.5 5v5.25l4.5 2.67-.75 1.23L11 13V7Z"
      />
    </svg>
  );
}

export default SvgClock;
