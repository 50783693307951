import * as React from 'react';
import { SVGProps } from 'react';

function SvgProducticon(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      data-name="Group 3699"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 21.011 28.34"
      {...props}>
      <g data-name="Group 3698">
        <path
          data-name="Path 3558"
          d="M16.384 6.875h-2v-1a3.875 3.875 0 0 0-7.751 0v1h-2v-1a5.875 5.875 0 0 1 11.751 0Z"
          fill="#dedfe9"
        />
      </g>
      <path
        data-name="Path 3559"
        d="M19.12 8.429H1.891L0 26.068a2.269 2.269 0 0 0 2.26 2.272h16.479a2.278 2.278 0 0 0 2.272-2.272Zm-8.615 9.547a6.082 6.082 0 0 1-6.075-6.075 1.2 1.2 0 1 1 2.4 0 3.676 3.676 0 0 0 7.351 0 1.2 1.2 0 1 1 2.4 0 6.082 6.082 0 0 1-6.076 6.075Z"
        fill="#dedfe9"
      />
    </svg>
  );
}

export default SvgProducticon;
